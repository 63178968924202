
<script>
/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /*onLangClick() {
      if (this.$i18n.locale == "ko") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "ko";
      };
    },*/

    onLangClick(lang = 'ko' | 'en') {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="images/logo_full_tight.png" height="20" alt="" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="images/logo_full_tight.png" class="l-dark" height="20" alt="" />
            <img
              src="images/logo_full_tight_white.png"
              class="l-light"
              height="20"
              alt=""
            />
          </router-link>
        </div>
      
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation" >
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
          
            <li>
              <router-link to="/team" class="side-nav-link-ref">{{ $t("message.team") }}</router-link>
            </li>

            <li>
              <router-link to="/partner" class="side-nav-link-ref">{{ $t("message.partner") }}</router-link>
            </li>

            <li>
              <router-link to="/social" class="side-nav-link-ref">{{ $t("message.social") }}</router-link>
            </li>
       
            <li> 
              <a href="https://www.auctionok.co.kr/" target="_blank">{{ $t("message.projects4") }}</a>
            </li>

            <b-nav-item-dropdown text="Whitepaper" right>
              <b-dropdown-item href="https://firebasestorage.googleapis.com/v0/b/landbox-landingcms.appspot.com/o/flamelink%2Fmedia%2FLANDBOX%20Spin-off%202024(WhitePaper_v5)_en-1_240424_130732.pdf?alt=media&token=2e220673-e9a1-45c0-b195-6965abe542c3">English</b-dropdown-item>
              <b-dropdown-item href="https://firebasestorage.googleapis.com/v0/b/landbox-landingcms.appspot.com/o/flamelink%2Fmedia%2FLANDBOX%20Spin-off%202024(WhitePaper_v5)_kr-1_240424_130937.pdf?alt=media&token=7d89f89c-45ab-4e2e-b7b0-525562fd2ef1">Korean</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item @click="onLangClick('ko')">Korean</b-dropdown-item>
              <b-dropdown-item @click="onLangClick('en')">English</b-dropdown-item>
            </b-nav-item-dropdown>

            <li> 
              <div class="buy-button" >
                <a
                  href="https://mypage.landbox.io"
                  target="_blank"
                  class="btn"
                  :class="{
                    'btn-primary': navLight === true,
                    'btn-light': navLight !== true,
                  }"
                  >My Page</a
                >
              </div>
              <div class="buy-button" >
                <a
                  href="https://docs.google.com/spreadsheets/d/1miSPazaaQy7IEiN4fWSk0ifelTgbSOvn9qfM871MsRc/edit#gid=745196740"
                  target="_blank"
                  class="btn btn-success"
                  >{{ $t("message.projects5") }}</a
                >
              </div>
              <div class="buy-button" >
                <a
                  href="https://docs.google.com/spreadsheets/d/1miSPazaaQy7IEiN4fWSk0ifelTgbSOvn9qfM871MsRc/edit#gid=745196740"
                  target="_blank"
                  class="btn btn-secondary"
                  >{{ $t("message.projects6") }}</a
                >
              </div>
            </li>

            <!--<li>
              <div class="lang-button side-nav-link-ref">
                <a @click="onLangClick()" class="btn btn-light">{{ $t("message.langBtn") }}</a>
              </div>
            </li>-->
            
          </ul>
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>
