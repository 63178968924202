<script>
import {
    ArrowUpIcon,
    MailIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MessageSquareIcon,
    SendIcon,
    MessageCircleIcon,
    YoutubeIcon,
    FileTextIcon,
    BellIcon,
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";

/**
 * Page-contact-detail component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
       ArrowUpIcon,
        MailIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
       MessageSquareIcon,
        SendIcon,
        MessageCircleIcon,
        YoutubeIcon,
        FileTextIcon,
        BellIcon,
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="vh-100 d-flex align-items-center" style="background: url('images/communi.jpg') center center;">
        <div class="bg-overlay bg-overlay-white"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
                    <div class="title-heading mt-5 pt-4">
                        <h1 class="heading">{{ $t("message.comm1") }}</h1>
                        <p class="text-dark">{{ $t("message.comm2") }}<span class="text-primary font-weight-bold">{{ $t("message.landbox") }}</span> {{ $t("message.comm3") }}</p>

                        <ul class="list-unstyled social-icon mb-0 mt-4">
                            <li class="list-inline-item ml-1">
                                <a href="https://twitter.com/Landbox_" class="rounded" target="_blank">
                                <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                </a>
                            </li>
                            <li class="list-inline-item ml-1">
                                <a href="https://t.me/LandToken" class="rounded" target="_blank">
                                <bell-icon class="fea icon-sm fea-social"></bell-icon>
                                </a>
                            </li>
                            <li class="list-inline-item ml-1">
                                <a href="https://t.me/landtoken_en" class="rounded" target="_blank">
                                <send-icon class="fea icon-sm fea-social"></send-icon>
                                </a>
                            </li>
                            <li class="list-inline-item ml-1">
                                <a href="https://t.me/landbox_kr" class="rounded" target="_blank">
                                <message-square-icon class="fea icon-sm fea-social"></message-square-icon >
                                </a>
                            </li>
                            <li class="list-inline-item ml-1">
                                <a href="https://open.kakao.com/o/gvPKHplc" class="rounded" target="_blank">
                                <message-circle-icon class="fea icon-sm fea-social"></message-circle-icon >
                                </a>
                            </li>
                            <li class="list-inline-item ml-1">
                                <a href="https://bit.ly/3sLiiH0" class="rounded" target="_blank">
                                <youtube-icon class="fea icon-sm fea-social"></youtube-icon >
                                </a>
                            </li>
                            
                            <li class="list-inline-item ml-1">
                                <a href="https://medium.com/landbox-news" class="rounded" target="_blank">
                                <file-text-icon class="fea icon-sm fea-social"></file-text-icon >
                                </a>
                            </li>
                        </ul>
                        <!--end icon-->

                        
                        <p class="text-dark">{{ $t("message.comm4") }}</p>
                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                            </div>
                            

                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Email</h5>
                                <a href="mailto:info@landbox.io" class="text-primary">info@landbox.io</a>
                            </div>
                        </div>

                        
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
    <!--end section-->
  
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
</style>
